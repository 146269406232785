<template>
  <AppLayout>
    <template v-slot:appContent>
      <section class="users-list-wrapper">
        <div class="d-sm-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0">
          <h2 class="">All Expense</h2>
          <div>
            <router-link :to="{ name: 'app.account.expense.create' }">
              <button type="button" class="btn btn-primary glow mb-1">
                <span class=""><i class="bx bx-plus"></i></span>
                <span class="align-middle ml-25">Create Expense</span>
              </button>
            </router-link>
          </div>
        </div>
        <div class="users-list-filter px-1 my-2 py-2 border rounded">
          <div>
            <div class="row align-items-center ">
              <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                <label>Show Per Page</label>
                <VueMultiselect v-model="selectedPagination" class="" :options="pagination" :close-on-select="true"
                                label="name" track-by="name" :show-labels="false"/>
              </div>
              <div class="col-12 col-sm-6 col-md-3  col-lg-3">
                <label>Amount From</label>
                <input v-model="getExpenseParams.amount" class="form-control search-product-input-element"
                       type="text"
                       placeholder="Search amount">
              </div>
            </div>
            <div class="row pt-2">
              <div class="col-12 col-sm-12 col-md-9  col-lg-9">
                <input v-model="getExpenseParams.name" class="form-control search-product-input-element"
                       type="text"
                       placeholder="Search a expense by name">
              </div>
              <div class="col-12 col-sm-12 col-md-3 col-lg-3">
                <button type="reset" class="btn btn-primary btn-block glow users-list-clear mb-0"
                        @click="applyExpenseFilter(null)">Filter
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="users-list-table">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <table id="users-list-datatable" class="table">
                  <thead>
                  <tr>
                    <th class="position-relative" :style="{ width: '18%' }">
                      AUTHOR
                      <div class="position-absolute sorting-up-button">
                        <i class="bx bx-chevron-up"></i>
                      </div>
                      <div class="position-absolute sorting-down-button">
                        <i class="bx bx-chevron-down"></i>
                      </div>
                    </th>
                    <th class="position-relative" :style="{ width: '18%' }">
                      EXPENSE TYPE
                      <div class="position-absolute sorting-up-button">
                        <i class="bx bx-chevron-up"></i>
                      </div>
                      <div class="position-absolute sorting-down-button">
                        <i class="bx bx-chevron-down"></i>
                      </div>
                    </th>
                    <th class="position-relative" :style="{ width: '18%' }">
                      AMOUNT
                      <div class="position-absolute sorting-up-button">
                        <i class="bx bx-chevron-up"></i>
                      </div>
                      <div class="position-absolute sorting-down-button">
                        <i class="bx bx-chevron-down"></i>
                      </div>
                    </th>
                    <th class="position-relative" :style="{ width: '18%' }">
                      DATE
                      <div class="position-absolute sorting-up-button">
                        <i class="bx bx-chevron-up"></i>
                      </div>
                      <div class="position-absolute sorting-down-button">
                        <i class="bx bx-chevron-down"></i>
                      </div>
                    </th>
                    <th class="position-relative" :style="{ width: '18%' }">
                      NOTE
                      <div class="position-absolute sorting-up-button">
                        <i class="bx bx-chevron-up"></i>
                      </div>
                      <div class="position-absolute sorting-down-button">
                        <i class="bx bx-chevron-down"></i>
                      </div>
                    </th>
                    <th class="position-relative" :style="{ width: '5%' }">
                      ACTIONS
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(expense)  in expenses" :key="expense.id">
                    <td>{{ fullName(expense.created_by) }}</td>
                    <td>{{ expense.expense_type.name }}</td>
                    <td>{{ expense.amount }}</td>
                    <td>{{ expense.date }}</td>
                    <td>{{ expense.note }}</td>
                    <td>
                      <div class="d-flex align-items-top justify-content-between">
                        <div>
                          <div class="cursor-pointer" :style="{height:'18px'}"
                               @click="getSingleExpense(expense.id)" data-toggle="modal"
                               data-target="#expenseDetails">
                            <i class='bx bx-show'></i>
                          </div>
                        </div>
                        <router-link class="text-decoration-none"
                                     :to="{name:'app.account.expense.edit',params:{id:expense.id}}">
                          <div :style="{height:'18px'}">
                            <i class='bx bx-edit'></i>
                          </div>
                        </router-link>
                        <div class="text-decoration-none cursor-pointer">
                          <div data-toggle="modal" data-target="#deleteModal" :style="{height:'18px'}">
                            <i class='bx bx-x'></i>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <ListPagination position="right" :pagination-data="paginateLinks"
                                @getClickedPage="applyExpenseFilter"/>
              </div>
            </div>
          </div>
        </div>
        <ExpenseDetails :expense="this.expense"/>
        <!-- <DeleteAlertModal/> -->
      </section>
    </template>
  </AppLayout>
</template>

<script>
import AppLayout from "@/layouts/backEnd/AppLayout.vue";
// import DeleteAlertModal from "@/components/backEnd/modal/DeleteAlertModal";
import ListPagination from '@/components/backEnd/pagination/ListPagination';
import ExpenseDetails from "@/views/backEnd/AccountAndFinance/Account/expenses/includes/ExpenseDetails";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "ExpenseList",
  components: {
    // DeleteAlertModal,
    AppLayout,
    ExpenseDetails,
    ListPagination
  },
  data() {
    return {
      selectedPagination: {
        value: '',
        name: 'Default'
      },

      getExpenseParams: {
        name: '',
        amount: '',
        expense_type_id: '',
        order_by_id: 'desc',
        with_relation: ['expenseType', 'createdBy'],
        paginate: 1,
        pagination: '',
        page: ''
      },
      expense: {}
    };
  },
  watch: {
    selectedPagination: function (pagination) {
      this.getExpenseParams.pagination = pagination.value;
    },
  },
  computed: {
    ...mapGetters({
      expenses: 'appExpenses/expenses',
      paginateLinks: 'appExpenses/paginateLinks',
    }),
    pagination() {
      return [
        {value: this.$store.getters['appSettings/settingDefaultPagination'].value, name: 'Default'},
        {value: 25, name: '25 Entries'},
        {value: 50, name: '50 Entries'},
        {value: 100, name: '100 Entries'}
      ];
    },
  },
  methods: {
    ...mapActions({
      getExpenses: 'appExpenses/getExpenses',
      getExpense: 'appExpenses/getExpense'
    }),
    async getExpenseList(expenseTypesParam) {
      await this.getExpenses(expenseTypesParam).then(async (response) => {
        if (response && response.message && response.status !== 200) {
          this.showToastMessage(response);
        }
      })
    },
    async applyExpenseFilter(pageNumber) {
      this.getExpenseParams.page = pageNumber;
      await this.getExpenseList(this.getExpenseParams);
    },
    async getSingleExpense(id) {
      const params = {
        id: id,
        params: this.getExpenseParams,
      };
      await this.getExpense(params)
          .then((response) => {
            if (response && response.status && response.status === 200) {
              this.expense = this.$store.getters['appExpenses/expense'];
            }

            if (response && response.message && response.status !== 200) {
              this.showToastMessage(response);
            }

          });
    },
    fullName(obj) {
      return `${obj.first_name} ${obj.last_name}`
    }
  },
  mounted() {
    this.getExpenseList(this.getExpenseParams)
  }
};
</script>

<style scoped>
@media only screen and (max-width: 1337px) and (min-width: 320px) {
  .table {
    white-space: nowrap !important;
  }
}
</style>
