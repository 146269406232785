<template>
  <div class="modal fade " id="expenseDetails" tabindex="-1" role="dialog"
       aria-labelledby="warehouseDetailsModal"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-lg">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h5 class="modal-title white" id="">Expense Details</h5>
          <button @click="resetExpense" type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <table class="table table-striped">
            <tbody>
            <tr>
              <th class="col-3">Created By</th>
              <th class="col-9">{{ created_by }}</th>
            </tr>
            <tr>
              <th class="col-3">Expense Type</th>
              <th class="col-9">{{ expense_type }}</th>
            </tr>
            <tr>
              <th class="col-3">Amount</th>
              <th class="col-9">{{ amount }}</th>
            </tr>
            <tr>
              <th class="col-3">Note</th>
              <th class="col-9">{{ note }}</th>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer border-0 py-1">
          <button @click="resetExpense" type="button" class="btn btn-primary" data-dismiss="modal">
            <i class="bx bx-x d-block d-sm-none"></i>
            <span class="d-none d-sm-block">Close</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ExpenseDetails",
  props: ['expense'],
  data() {
    return {
      expense_type: '',
      created_by: '',
      amount: '',
      note: '',
      date: ''
    }
  },
  watch: {
    expense() {
      this.amount = this.expense && this.expense.amount ? this.expense.amount : '';
      this.note = this.expense && this.expense.note ? this.expense.note : '';
      this.date = this.expense && this.expense.date ? this.expense.date : '';
      this.expense_type = this.expense && this.expense.expense_type ? this.expense.expense_type.name : '';
      this.created_by = this.expense && this.expense.created_by ? (this.expense.created_by.first_name + '' + this.expense.created_by.last_name) : '';

    },
  },
  methods: {
    resetExpense() {
      Object.assign(this.$data, this.$options.data());
    }
  }
}
</script>

<style scoped>

</style>